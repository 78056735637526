import { defineComponent, ref, computed } from 'vue'
import '../style/index.scss'
import { RightOutlined } from '@ant-design/icons-vue';
export default defineComponent({
  name: 'DrawerComponent',
  emits: ['update:open', 'cancel'],
  props: {
    closable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots, attrs, expose, emit}) {
    const { closable } = { ...props }
    const { onCancel } = attrs
    const visible = ref(false)

    const isOpen = computed({
      get: () => visible.value,
      set: (value) => {
        visible.value = value
      },
    })

    const handleCancel = () => {
      isOpen.value = false
      emit('cancel')
    }

    expose({ isOpen })

    // transitionName=""
    return () => (
      <a-drawer
        width="500px"
        class="ui-drawer"
        v-model:open={visible.value}
        closable={closable}
        onCancel={onCancel || (() => (isOpen.value = false))}
        {...attrs}
        v-slots={{closeIcon: () => (<span>关闭</span>)}}
      >
        <div class="closeIcon" onClick={handleCancel}><RightOutlined class="icon" /></div>
        {slots.default && isOpen.value && slots.default()}
      </a-drawer>
    )
  },
})
