import { createStore } from "vuex";
import { httpGoout } from "@/http/service/login/index";
import router from "@/router/index";
import dayjs from "dayjs";
interface State {
  showLoading: boolean;
  userInfo: any;
  token: string | null;
  isOUt: boolean;
  breadcrumb: any[];
  newPersonInfo: any;
  videoImgUrl: string | null;
  isUploadStatus: boolean;
  storeUploadList: any[];
  fileUrl: string | null
}
export const setBreadcrumbInit = (list: any[]) => {
  let _arr: { name: any; meta: any }[] = [];
  if (list && list.length) {
    _arr = list.map((item) => {
      return {
        name: item.name,
        meta: item.meta,
      };
    });
  }
  return _arr;
};
export default createStore<State>({
  state: {
    newPersonInfo: localStorage.getItem("newPersonInfo")
      ? JSON.parse(localStorage.getItem("newPersonInfo") as any)
      : {},
    videoImgUrl: sessionStorage.getItem("imgUrl")
      ? sessionStorage.getItem("imgUrl")
      : null,
    showLoading: false,
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo") as string)
      : {},
    token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
    isOUt: true, // 点登录默认退出
    breadcrumb: sessionStorage.getItem("breadcrumb")
      ? JSON.parse(sessionStorage.getItem("breadcrumb") as any)
      : [], // 面包屑导航
    isUploadStatus: false, // 上传列表默认隐藏
    storeUploadList: [],
    fileUrl: sessionStorage.getItem('fileUrl') ? sessionStorage.getItem('fileUrl') : '' // 上传列表
  },
  getters: {},
  mutations: {
    setNewPersonInfo(state, value) {
      state.newPersonInfo = value;
      if (value) {
        localStorage.setItem("newPersonInfo", JSON.stringify(value));
      } else {
        localStorage.removeItem("newPersonInfo");
      }
    },
    setBreadcrumb(state, value) {
      if (value) {
        const _arr = setBreadcrumbInit(value);
        state.breadcrumb = _arr;
        sessionStorage.setItem("breadcrumb", JSON.stringify(_arr));
      } else {
        sessionStorage.removeItem("breadcrumb");
      }
    },
    setIsOut(state, value) {
      state.isOUt = value;
    },
    setShowLoadingVal(state, value) {
      state.showLoading = value;
    },
    setUserInfo(state, value) {
      state.videoImgUrl = value;
      state.userInfo = value;
      if (value) {
        localStorage.setItem("userInfo", JSON.stringify(value));
      } else {
        localStorage.removeItem("userInfo");
      }
    },
    setToken(state, value) {
      state.token = value;
      if (value) {
        localStorage.setItem("token", value);
      } else {
        localStorage.removeItem("token");
      }
    },
    setFileUrl(state, value) {
      state.fileUrl = value;
      if (value) {
        sessionStorage.setItem("fileUrl", value);
      } else {
        sessionStorage.removeItem("fileUrl");
      }
    }
  },

  actions: {
    setShowLoading({ commit }, value) {
      commit('setShowLoadingVal', value)
    },
    goHome({ commit, state }) {
      httpGoout().then(res => {
        router
          .replace({
            name: "login",
          })
          .then(() => {
            if (state.isOUt) {
              commit("setUserInfo");
              commit("setToken");
            }
          });
      })
    },
  },
  modules: {},
});
