import { defineComponent, ref, watch } from 'vue'
import '../style/index.scss'
import { httpCaptcha } from '@/http/service/login/index'
import { message } from 'ant-design-vue'

const _msg = '获取验证码'
const _count = 60 // 60s

export default defineComponent({
  name: 'CaptchaComponent',
  props: {
    counts: {
      type: Number,
      default: _count,
    },
    fetch: {
      type: Function,
    },
    value: {
      type: [Number, String],
      required: true,
    },
    type: {
      type: String,
    },
    // default: CaptchaTypeEnum.register,
  },
  setup(props) {
    let timer: number | null | undefined = null
    // 点击按钮
    const msg = ref(_msg)
    const count = ref(props.counts)
    const resetData = () => {
      count.value = props.counts
      msg.value = _msg
      timer && clearTimeout(timer)
    }
    // 验证码倒计时
    const reloadTime = () => {
      timer = setTimeout(() => {
        if (count.value <= 1) {
          resetData()
        } else {
          count.value--
          msg.value = count.value.toString() + '秒后重发'
          reloadTime()
        }
      }, 1000)
    }
    // 走默认发送验证码接口地址
    const fetchRequest = () => {
      const _pramas = props.type
        ? {
            captchaType: props.type,
            phoneNumber: props.value,
          }
        : {
            phoneNumber: props.value,
          }
      if (props.fetch) {
        props
          .fetch({ ..._pramas })
          .then((res: { data: { captcha: any } }) => {
            // message.success(`验证码发送成功,请注意查收${res.data.captcha}`)
            message.success(`验证码发送成功,请注意查收`)
          })
          .catch(() => {
            resetData()
          })
      } else {
        // 执行默认操作
        httpCaptcha({ ..._pramas })
          .then((res) => {
            message.success(`验证码发送成功,请注意查收`)
          })
          .catch(() => {
            resetData()
          })
      }
    }
    const handleClick = () => {
      const reg = /^1[3-9][0-9]{9}$/
      if (msg.value !== _msg) return false

      if (props.value && reg.test(props.value.toString())) {
        reloadTime()
        fetchRequest()
      } else {
        // message.error('检查手机号是否正确')
      }
    }

    const checkPhone = (value: string | number) => {
      const reg = /^1[3-9][0-9]{9}$/
      return reg.test(value.toString())
    }

    const isPhone = ref(false)
    watch(
      () => props.value,
      (newValue) => {
        isPhone.value = checkPhone(newValue)
      },
      {
        immediate: true,
      },
    )
    return () => {
      return (
        <a-button
          class="btn-base"
          type="primary"
          onClick={handleClick}
          disabled={!isPhone.value}
        >
          {msg.value}
        </a-button>
      )
    }
  },
})
