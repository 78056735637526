import type { App } from 'vue'
import LayerComponents from './layers/index'
import DrawerComponent from './drawer/index'
import CaptchaComponent from './captcha/index'
// import LayerSlotComponent from './layerSlot/index'
// import ParagraphComponent from './paragraph/index'
// import noDataComponent from './noData/index'

const components = [
  CaptchaComponent,
  // LayerSlotComponent,
  // ParagraphComponent,
  LayerComponents,
  DrawerComponent,
  // noDataComponent,
]

export function registerElement(app: App) {
  for (const cmp of components) {
    app.component(cmp.name, cmp)
  }
}
