export default {
  user: {
    kossInit: '/sheet/koss/init',
    login: '/sheet/login', // 密码登录
    
    goout: '/sheet/logout', // 退出登录

    captcha: '/user/captcha', // 验证码 暂无用

    forgetCaptcha: '/sheet/password/forget/captcha', // 忘记密码验证码
    forgetPwd: '/sheet/password/reset', // 忘记密码

    rebindCode: '/sheet/account/rebind/captcha', // 换绑手机-原手机号获取验证码
    bindCode: '/sheet/account/bind/captcha', // 换绑手机-新手机号获取验证码
    rebindPhone: '/sheet/account/rebind/captcha', // 换绑手机-原手机号验证
    bindPhone: '/sheet/account/bind', // 换绑手机-新手机号绑定

    putPwd: '/sheet/password/change', // 修改密码
    changePwd: '/sheet/password/change/captcha', // 修改密码验证码

    addFinger: '/sheet/fingerprint', // 用户添加指纹

  },
  personnel: {
    list: '/sheet/account/list', // 人员列表
    account: '/sheet/account', // 添加 修改用户
    profile: '/sheet/account/profile', // 用户详情
    noGroupList: '/sheet/account/no_group/list', // 没有加入班组的成员列表
    forTask: '/sheet/account/for_task/list', // 任务用户列表
    resetPwd: '/sheet/account/password', // 重置密码
  },
  teams: {
    list: '/sheet/workgroup/list', // 班组列表
    workGroup: '/sheet/workgroup', // 添加 删除 修改班组 班组详情
  },
  task: {
    list: '/sheet/task/list',
    task: '/sheet/task', // 添加 删除 任务
    taskFid: '/sheet/task/fid', // 添加 删除 任务-指纹

    taskMember: '/sheet/task/member', // 设置任务成员
    listForTask: '/sheet/workgroup/list_for_task', // 任务可选班组列表
    memberList: '/sheet/workgroup/member/list', // 班组成员列表
    sheetChoose: '/sheet/task/sheet/choose', // 为任务选择操作票
    sheetChooseFid: '/sheet/task/sheet/choose/fid', // 为任务选择操作票-指纹
    sheet: '/sheet/task/sheet', // 设置任务操作票
    // sheetFid: '/sheet/task/sheet/fid', // 设置任务操作票-指纹
    log: '/sheet/task/log', // 获取任务审批日志
    code: '/sheet/task/work/qr_code', // 生成领取任务的二维码
    receive: '/sheet/task/work/receive', // 扫描二维码领取任务
    simulateTicket: '/sheet/task/simulate/confirm', // 模拟操作审核后负责人审核
    simulateTicketFid: '/sheet/task/simulate/confirm/fid', // 模拟操作审核后负责人审核-指纹
    simulateReject: '/sheet/task/simulate/reject', // 模拟操作驳回
    simulateRejectFid: '/sheet/task/simulate/reject/fid', // 模拟操作驳回-指纹

    finishConfirm: '/sheet/task/finish/confirm', // 模拟操作完成后负责人确认
    finishConfirmFid: '/sheet/task/finish/confirm/fid', // 模拟操作完成后负责人确认 指纹

  },
  ticket: {
    list: '/sheet/operation_sheet/list', // 操作票列表
    sheet: '/sheet/task/sheet', // 设置任务操作票
    sheetFid: '/sheet/task/sheet/fid', // 设置任务操作票-指纹
    
    sheetAppRove: '/sheet/task/sheet/approve', // 审核任务操作票
    sheetAppRoveFid: '/sheet/task/sheet/approve/fid', // 审核任务操作票-指纹

    sheetReject: '/sheet/task/sheet/reject', // 驳回任务操作票
    sheetRejectFid: '/sheet/task/sheet/reject/fid', // 驳回任务操作票-指纹

    sheetDetail: '/sheet/operation_sheet', // 获取操作票信息
    addTicket: '/sheet/operation_sheet/add', // 添加操作票
    oss: '/sheet/oss/sts', // 获取oss-config

    sheetNo: '/sheet/operation_sheet/sheet_no', // 修改票号
    pushTask: 'sheet/task/pmis', // 生产推送
  },
  device: {
    list: '/sheet/device/list', // 设备列表
    device: '/sheet/device', // 添加 删除 编辑
  },
  insCard: {
    addCard: '/sheet/inspection/card', // 添加巡视项目
    cardList: '/sheet/inspection/card/list', // 巡视项目管理列表
    pointList: '/sheet/inspection/point/list', // 巡视点位列表
    timeList: '/sheet/inspection/time/list', // 巡视时间列表
    addInsTime: '/sheet/inspection/time', // 添加巡视时间
    addInsPoint: '/sheet/inspection/point', // 添加巡视点位
    insRecordTime: '/sheet/inspection/record/time/list', // 巡视记录 时间
    insRecordStep: '/sheet/inspection/record/stepwise/list', // 巡视记录 逐条
    insRecordPoint: '/sheet/inspection/record/point/list', // 巡视记录 点位
    insRecordDetail: '/sheet//inspection/record/special', // 巡视记录详情 逐条
    recordTime: '/sheet/inspection/record/time', // 巡视记录 时间 详情
    recordLocation: '/sheet/inspection/record/location', // 巡视记录 点位 详情
  }
}
