import axiosRequest from "../../index";
import Api from "../../api";
import type {
  LoginType,
  CaptchaType,
  RegisterType,
  ForgetPwdType,
  IUpdatePwdData,
} from "./types";
// import '@/mock/http/login.mock'

// 登录
export const httpLogin = (data: LoginType) =>
  axiosRequest.post({
    url: Api.user.login,
    data,
    isToken: false,
  });

// 忘记密码验证码
export const httpPwdCaptcha = (params: { phoneNumber: string | number }) =>
  axiosRequest.get({
    url: Api.user.forgetCaptcha,
    params,
    isToken: false,
  });

// 获取验证码
export const httpCaptcha = (data: CaptchaType) =>
  axiosRequest.post({
    url: Api.user.captcha,
    data,
    isToken: false,
  });

// 忘记密码
export const httpForgetPwd = (data: ForgetPwdType) =>
  axiosRequest.post({
    url: Api.user.forgetPwd,
    data,
    isToken: false,
  });

// 退出登录
export const httpGoout = () =>
  axiosRequest.post({
    url: Api.user.goout,
  });

// 修改密码
export const httpPutPwd = (data: IUpdatePwdData) =>
  axiosRequest.post({
    url: Api.user.putPwd,
    data,
  });

// 修改密码验证码
export const httpChangePwd = (data: IUpdatePwdData) =>
  axiosRequest.get({
    url: Api.user.changePwd,
  });

// 换绑手机-原手机号获取验证码
export const httpRebindCode = (data: IUpdatePwdData) =>
  axiosRequest.get({
    url: Api.user.rebindCode,
  });

// 换绑手机-新手机号获取验证码
export const httpBindCode = (params: { phoneNumber: string | number }) =>
  axiosRequest.get({
    url: Api.user.bindCode,
    params,
  });

//  换绑手机-原手机号验证
export const httpRebindPhone = (data: IUpdatePwdData) =>
  axiosRequest.post({
    url: Api.user.rebindPhone,
    data,
  });
//  换绑手机-新手机号绑定
export const httpBindPhone = (data: IUpdatePwdData) =>
  axiosRequest.post({
    url: Api.user.bindPhone,
    data,
  });

// // 开始采集
// export const httpStartCapture = (data: any) =>
//   axiosRequest.get({
//     cusTomBaseURL: "http://localhost:8000",
//     url: Api.user.startCapture,
//     isCusTomBaseURL: true,
//     isToken: false,
//   });

// // 获取结果
// export const httpGetResult = (data: any) =>
//   axiosRequest.get({
//     cusTomBaseURL: "http://localhost:8000",
//     url: Api.user.getResult,
//     isCusTomBaseURL: true,
//     isToken: false,
//   });

// // 注册指纹
// export const httpRegistration = (data: any) =>
//   axiosRequest.get({
//     cusTomBaseURL: "http://localhost:8000",
//     url: Api.user.registration,
//     isCusTomBaseURL: true,
//     isToken: false,
//   });

// // 验证注册指纹
// export const httpRegistrationStatus = (data: any) =>
//   axiosRequest.get({
//     cusTomBaseURL: "http://localhost:8000",
//     url: Api.user.registrationStatus,
//     isCusTomBaseURL: true,
//     isToken: false,
//   });

//  用户添加指纹
export const httpAddFinger = (data: any) =>
  axiosRequest.post({
    url: Api.user.addFinger,
    data,
  });
//  获取单位指纹列表
export const httpSchoolFinger = (params: any) =>
  axiosRequest.get({
    url: Api.user.addFinger,
    params,
  });

  //  换绑手机-新手机号绑定
export const httpKossInit = (data: any) =>
axiosRequest.post({
  url: Api.user.kossInit,
  data,
});
