import type { AxiosRequestConfig, AxiosResponse } from 'axios'

interface HMRequestInterceptors<T = AxiosResponse> {
  requestInterceptor?: (config: AxiosRequestConfig) => AxiosRequestConfig
  requestInterceptorCatch?: (error: any) => any
  responseInterceptor?: (res: T) => T
  responseInterceptorCatch?: (error: any) => any
}

interface HMRequestConfig<T = AxiosResponse> extends AxiosRequestConfig {
  showLoading?: boolean
  showMsg?: boolean
  isToken?: boolean
  interceptors?: HMRequestInterceptors<T>
  isSpecial?: boolean
  isShowError?: boolean
  isCusTomBaseURL?: boolean
  cusTomBaseURL?: String
}

/**
 * 消息代码定义。<br/>
 * <br/>
 * I#### 代表提示信息。<br/>
 * W#### 代表警告信息。<br/>
 * E#### 代表一般错误。<br/>
 * F#### 代表预期外错误。<br/>
 * <br/>
 * *1000 ~ *1999 系统通用信息<br/>
 * *2000 ~ *9999 各种业务信息<br/>
 */

enum EErrorCodeConfig {
  /** 用户不存在 */
  USER_NOT_EXISTS = 'F101',
  /** 用户已存在 */
  USER_EXISTS = 'F102',
  /** 手机验证未通过 */
  VALIDATE_NOT = 'F105',
  /** 验证码错误 */
  INVALID_CAPTCHA = 'F106',
  /** 邮箱验证失败 */
  EMAIL_ERROR = 'F109',
  /** 手机号或密码错误 */
  NO_USER_FOUND = 'F400',
  /** 原密码错误 */
  PASSWORD_ERROR = 'F111',
  /** 用户状态错误 */
  USER_STATE_INVALID = 'F117',

  /** 账号过期 */
  ACCOUNT_EXPIRED = 'F112',
  /** 密码过期 */
  CREDENTIALS_EXPIRED = 'F118',
  /** 账号锁定 */
  ACCOUNT_LOCKED = 'F121',
  /** 账号无效 */
  ACCOUNT_DISABLED = 'F408',
  /** 用户禁止状态 */
  ACCOUNT_ERROR_STATUS = 'F410',
  /** 同一手机在60秒内重发超过5次 */
  OVERCLOCK = 'F113',

  /** 请求参数错误或者为空 */
  INPUT_VALIDATION_ERROR = 'E101',
  /** 未知异常 */
  UNEXPECTED = 'E103',
  /** 数据库异常 */
  DB_ERROR = 'E204',
  /** 文件服务器异常 */
  IO_ERROR = 'E105',

  /** 上传至OSS时发生错误 */
  UPLOAD_TO_OSS_ERROR = 'F203',
  /** 等待上传至OSS的临时文件不存在(通常为处理超时) */
  FILE_NOT_EXISTS_FOR_OSS = 'F205',
  /** OSS Api错误 */
  OSS_API_ACCESS_ERROR = 'F206',
  /** 没找到OSS文件 */
  OSS_FILE_NOT_FOUND = 'F207',
  /** 没找到文件 */
  FILE_NOT_FOUND = 'F208',
  /** SSH命令错误 */
  SSH_COMMAND_ERROR = 'F209',
  /** 上传文件超出大小限制错误 */
  MAX_UPLOAD_SIZE_EXCEEDED = 'F210',
  /** 短信发送错误 */
  ERROR_SEND = 'F211',
  /** 操作数据不存在 */
  NOT_DATA = 'F302',
  /** 未登录 */
  UNAUTHORIZED = 'F401',
  /** 没有权限 */
  FORBIDDEN = 'F403',
  /** ClientId或ClientSecret验证错误 */
  BAD_CLIENT_AUTH = 'F408',
}

enum EErrorCodeConfigValue {
  /** 用户不存在 */
  USER_NOT_EXISTS = '用户不存在',
  /** 用户已存在 */
  USER_EXISTS = '用户已存在',
  /** 手机验证未通过 */
  VALIDATE_NOT = '手机验证未通过',
  /** 验证码错误 */
  INVALID_CAPTCHA = '验证码错误',
  /** 邮箱验证失败 */
  EMAIL_ERROR = '邮箱验证失败',
  /** 手机号或密码错误 */
  NO_USER_FOUND = '手机号或密码错误',
  /** 原密码错误 */
  PASSWORD_ERROR = '原密码错误',
  /** 用户状态错误 */
  USER_STATE_INVALID = '用户状态错误',

  /** 账号过期 */
  ACCOUNT_EXPIRED = '账号过期',
  /** 密码过期 */
  CREDENTIALS_EXPIRED = '很抱歉，您无法登录该系统，请联系管理员处理',
  /** 账号锁定 */
  ACCOUNT_LOCKED = '很抱歉，您无法登录该系统，请联系管理员处理',
  /** 账号无效 */
  ACCOUNT_DISABLED = '账号无效',
  /** 用户禁止状态 */
  ACCOUNT_ERROR_STATUS = '很抱歉，您无法登录该系统，请联系管理员处理',
  /** 同一手机在60秒内重发超过5次 */
  OVERCLOCK = '同一手机在60秒内重发超过5次',

  /** 请求参数错误或者为空 */
  INPUT_VALIDATION_ERROR = '请求参数错误或者为空',
  /** 未知异常 */
  UNEXPECTED = '未知异常',
  /** 数据库异常 */
  DB_ERROR = '数据库异常',
  /** 文件服务器异常 */
  IO_ERROR = '文件服务器异常',

  /** 上传至OSS时发生错误 */
  UPLOAD_TO_OSS_ERROR = '上传至OSS时发生错误',
  /** 等待上传至OSS的临时文件不存在(通常为处理超时) */
  FILE_NOT_EXISTS_FOR_OSS = '临时文件不存在',
  /** OSS Api错误 */
  OSS_API_ACCESS_ERROR = '接口超时',
  /** 没找到OSS文件 */
  OSS_FILE_NOT_FOUND = '没找到OSS文件',
  /** 没找到文件 */
  FILE_NOT_FOUND = '没找到文件',
  /** SSH命令错误 */
  SSH_COMMAND_ERROR = 'SSH命令错误',
  /** 上传文件超出大小限制错误 */
  MAX_UPLOAD_SIZE_EXCEEDED = '上传文件超出大小限制错误',
  /** 短信发送错误 */
  ERROR_SEND = '短信发送错误',
  /** 操作数据不存在 */
  NOT_DATA = '操作数据不存在',
  /** 未登录 */
  UNAUTHORIZED = '登录过期',
  /** 没有权限 */
  FORBIDDEN = '没有权限',
  /** ClientId或ClientSecret验证错误 */
  BAD_CLIENT_AUTH = 'ClientId或ClientSecret验证错误',
}

enum IHandleType {
  unHandled = 0,
  then = 1,
  catch = 2,
}

export {
  HMRequestInterceptors,
  HMRequestConfig,
  EErrorCodeConfig,
  IHandleType,
  EErrorCodeConfigValue,
}
