import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login/login.vue')
  },
  {
    path: '/forgotPwd',
    name: 'forgotPwd',
    component: () => import('../views/ForfotPwd/forgotPwd.vue')
  },
  {
    path: '/layoutView',
    name: 'layoutView',
    component: () => import('../views/Layout/layoutView.vue'),
    redirect: '/taskView',
    children: [
      {
        path: '/taskView',
        name: 'taskView',
        component: () => import('../views/Task/TaskView.vue'),
        meta: {
          name: '任务列表'
        }
      },
      {
        path: '/personnelView',
        name: 'personnelView',
        component: () => import('../views/Personnel/personnelView.vue'),
        meta: {
          name: '人员列表'
        }
      },
      {
        path: '/teamsGroupsView',
        name: 'teamsGroupsView',
        component: () => import('../views/Teams/teamsGroupsView.vue'),
        meta: {
          name: '班组管理'
        }
      },
      {
        path: '/operationView',
        name: 'operationView',
        component: () => import('../views/Operation/operationView.vue'),
        meta: {
          name: '操作票库'
        }
      },
      {
        path: '/deviceView',
        name: 'deviceView',
        component: () => import('../views/Device/deviceView.vue'),
        meta: {
          name: '设备管理'
        }
      },
      {
        path: '/inspectionTimeView',
        name: 'inspectionTimeView',
        component: () => import('../views/InspectionCard/inspectionTimeView.vue'),
        meta: {
          name: '巡视时间管理'
        }
      },
      {
        path: '/inspectionContentView',
        name: 'inspectionContentView',
        component: () => import('../views/InspectionCard/inspectionContentView.vue'),
        meta: {
          name: '巡视内容管理'
        }
      },
      {
        path: '/inspectionPointView',
        name: 'inspectionPointView',
        component: () => import('../views/InspectionCard/inspectionPointView.vue'),
        meta: {
          name: '巡视点位管理'
        }
      },
      {
        path: '/inspectionRecordItemized',
        name: 'inspectionRecordItemized',
        component: () => import('../views/InspectionRecord/inspectionRecordItemized.vue'),
        meta: {
          name: '巡视记录（逐条）'
        }
      },
      {
        path: '/inspectionRecordPointView',
        name: 'inspectionRecordPointView',
        component: () => import('../views/InspectionRecord/inspectionRecordPointView.vue'),
        meta: {
          name: '巡视记录（点位）'
        }
      },
      {
        path: '/inspectionRecordTimeView',
        name: 'inspectionRecordTimeView',
        component: () => import('../views/InspectionRecord/inspectionRecordTimeView.vue'),
        meta: {
          name: '巡视记录（时间）'
        }
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
