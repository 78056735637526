import store from '@/store'
import AxiosRequest from './request/index'

// 筛选参数
function screenParams(obj: any) {
  const _obj: any = {}
  if (obj instanceof FormData || obj instanceof Array) return obj
  for (const key in obj) {
    if (
      (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') ||
      obj[key] === 'boolean'
    ) {
      _obj[key] = obj[key]
    }
  }
  return _obj
}

const axiosRequest = new AxiosRequest({
  // baseURL: '/api',
  baseURL: process.env.VUE_APP_SERVER_URL,
  timeout: 60000,
  interceptors: {
    requestInterceptor: (config: any) => {
      config.headers['x-client'] = 'sheet.web'
      config.headers['x-device'] = 1
      if(config.isCusTomBaseURL !== undefined && config.isCusTomBaseURL) {
        config.baseURL = config.cusTomBaseURL
      }
      const _isToken = config.isToken !== undefined ? config.isToken : true;
      // const _isToken = config.isToken ?? true
      // 参数请求做特殊处理
      if (
        config.method?.toLocaleLowerCase() === 'get' &&
        config.params !== undefined
      ) {
        config.params = screenParams(config.params)
      }
      if (
        config.method?.toLocaleLowerCase() === 'post' &&
        config.data !== undefined
      ) {
        if (!config.isSpecial) {
          config.data = screenParams(config.data)
        }
      }
      if (_isToken && config.headers) {
        config.headers.authorization = localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
      // // 做协同参数的特殊处理
      // const newPersonInfo = store.state.newPersonInfo
      // if (_isToken && config.isSpecial && newPersonInfo && config.headers) {
      //   config.headers.authorization = `${newPersonInfo.token.token_type} ${newPersonInfo.token.access_token}`
      // }
      return config
    },
  },
})

export default axiosRequest
