import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import './assets/font/iconfont.css'
import './assets/scss/for.scss'
import './assets/scss/main.scss'
import './assets/scss/reset.scss'
const print = require('vue3-print-nb')

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
import Mock from './mock' // 引入mock数据
import { registerElement } from '@/components/index'
import SvgIcon from '@/components/svgIcon/index.vue'
const app = createApp(App)
// 1. 全局注册SvgIcon组件
app.component('svg-icon', SvgIcon)
// 2. 载入所有svg icon
const requireContext = require.context('./assets/icons', false, /\.svg$/)
requireContext.keys().forEach(requireContext)


app.use(store).use(router).use(Antd).use(registerElement).use(print).mount('#app')
